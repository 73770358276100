$green: #0a4436;

.fade-enter {
	opacity: 0.01;
}

.fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 500ms ease-in;
}

.fade-leave {
	opacity: 1;
}

.fade-leave.fade-leave-active {
	opacity: 0.01;
	transition: opacity 300ms ease-in;
}

#controls {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently supported by Chrome and Opera */
	flex-grow: 1;
	margin-bottom: 5em;
	flex-direction: column;
	display: flex;

	h1 {
		margin-bottom: 0.3em;
	}

	.actions {
		text-align: center;
	}

	.button-area {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;

		button {
			//flex-grow: 1;
			text-align: center;
			margin: 12px 6px;
            padding: 12px;
            min-width: calc(50% - 12px);
            flex-grow: 1;
		}
    }
    
    .add-shape  {
        width: 360px;
    }

	.nav {
		justify-content: space-around;
		display: flex;
		margin-top: auto;
	}
	.slider,
	.joystick-base {
		margin-bottom: 0.5em;
	}
	.rangeslider-horizontal .rangeslider__fill {
		background-color: #0a4436;
	}
	.rangeslider.rangeslider-horizontal {
		margin-left: 4em;
		margin-right: 4em;
	}
	li.rangeslider__label-item svg {
		fill: black;
		stroke: black;
		width: 20px;
	}
}

.input-group {
	display: table;
	border-collapse: collapse;
	width: 100%;
	margin: 0.5em 0;
}

.input-group > div {
	display: table-cell;
	vertical-align: middle;
}

.input-group-label {
	min-width: 120px;
	font-family: "museo-slab", serif;
	text-transform: uppercase;
	position: relative;
	font-size: 16px;
	font-weight: 700;
	display: block;
	color: #0a4436;
}

.input-group-area {
	width: 100%;
}

.input-group input,
.sweet-alert.show-input input {
	border: 0;
	display: block;
	width: 100%;
	line-height: 1.5;
	background-color: #fff;
	border: 0.075rem solid #ddd;
	transition: border-color 0.15s linear, background-color 0.15s linear;
	border-radius: 0;

	&:focus {
		border-color: #0a4439;
		background-color: #fafafa;
	}
}

.group-list {
	margin: 0.6em 1em;
}

.group-list-item {
	display: block;
	text-align: left;
	margin: 0.5em 0;
	min-height: 50px;

	input {
		position: relative;
		top: 1em;
	}

	span {
		font-size: 18px;
		font-weight: 300;
		position: relative;
		top: 0.725em;
	}
}

input:not([type="radio"]) {
	padding-left: 12px;
	font-family: "din-2014", sans-serif;
	font-size: 15px;
	height: 42px;
	border-radius: 0;
	width: 205px;
	box-shadow: none;

	&:active,
	&:focus {
		outline: none;
		border-color: $green;
	}
}
#controls span.center {
	padding-top: 8px;
	text-transform: uppercase;
	font-family: "museo-slab", serif;
	font-weight: 900;
	text-transform: uppercase;
}

label > span {
	padding-left: 1em;
}

.Dropdown-disabled:hover {
	cursor: not-allowed;
}

.mm-popup__close {
	display: none;
}

button,
input[type="button"],
#signup-button,
#submit-login,
#recorver-password {
    cursor:pointer;
	font-family: "din-2014", sans-serif;
	text-transform: uppercase;
	font-size: 16px;
	letter-spacing: 0.03em;
	padding: 10px 32px;
	margin: 26px 5px 0 5px;
	background-color: #fff;
	color: #1d1d1d;
	font-weight: 700;
	border: solid 2px #fff;
	transition: 200ms all ease;

	&.small {
		font-size: smaller;
	}

	&.outline {
		background-color: transparent;
		color: #fff;
		border: solid 2px #fff;
	}

	&.red {
		background-color: #fff;
		color: #f44336;
        border-color: #f44336;
	}

	&.grey {
		background-color: #ccc;
		border-color: #ccc;
		color: #fff;
	}

	&.outline:hover,
	&.outline:active,
	&.outline:focus {
		background-color: #fff;
		color: #1d1d1d;
	}

	&.mm-popup__btn--cancel,
	&.outline-green {
		background-color: transparent;
		color: #0a4436;
		border: solid 2px #0a4436;
		padding: 9px 16px;
	}

	&.mm-popup__btn--cancel:hover,
	&.mm-popup__btn--cancel:active,
	&.mm-popup__btn--cancel:focus,
	&.outline-green:hover,
	&.outline-green:active,
	&.outline-green:focus {
		background-color: #0a4436;
		color: #fff;
	}

	&.red:hover,
	&.red:active,
	&.red:focus {
		
        background-color: #f44336;
		border-color: #f44336;
		color: #fff;
	}

	&.grey:hover,
	&.grey:active,
	&.grey:focus {
		background-color: #fff;
		color: #ccc;
		border-color: #ccc;
	}

	&.lblack {
		background-color: #3f3f3f;
		color: #fff;
		border: solid 2px #3f3f3f;
		padding: 9px 16px;
	}

	&.lblack:hover,
	&.lblack:active,
	&.lblack:focus {
		background-color: transparent;
		border-color: #fff;
		color: #fff;
	}

	&.mm-popup__btn--success,
	&.mm-popup__btn--ok,
	&.green {
		background-color: #0a4436;
		color: #fff;
		border: solid 2px #0a4436;
		padding: 9px 16px;
	}

	&.mm-popup__btn--success:hover,
	&.mm-popup__btn--success:active,
	&.mm-popup__btn--success:focus,
	&.mm-popup__btn--ok:hover,
	&.mm-popup__btn--ok:active,
	&.mm-popup__btn--ok:focus,
	&.green:hover,
	&.green:active,
	&.green:focus {
		background-color: #fff;
		color: #0a4436;
	}
}

.center {
	text-align: center;
}

.right {
	text-align: right;
}

.left {
	text-align: left;
}

.big-margin-top {
	margin-top: 1em;
}

.file {
	position: relative;
	display: inline-block;
	cursor: pointer;
}

.file input {
	min-width: 14rem;
	margin: 0;
	filter: alpha(opacity=0);
	opacity: 0;
	width: initial;
}

.file-custom {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 5;
	padding: 0.5rem 1rem;
	line-height: 1.5;
	color: #555;
	background-color: #fff;
	border: 0.075rem solid #ddd;
	box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
	user-select: none;
	text-align: left;

	&:after {
		content: "Choose an Image...";
	}

	&:before {
		position: absolute;
		text-transform: uppercase;
		font-family: "din-2014", sans-serif;
		font-weight: 700;
		display: block;
		color: white;
		top: -0.075rem;
		right: -0.075rem;
		bottom: -0.075rem;
		z-index: 6;
		content: "Browse";
		padding: 0.5rem 1rem;
		background-color: #0a4436;
		border: 0.075rem solid #ddd;
	}
}

.svgicon {
	display: inline-flex;
	align-self: center;
	position: relative;

	svg,
	img {
		fill: white;
		transform: scale(1.2);
		height: 16px;
		width: 16px;
		top: 0.125em;
		position: relative;
	}
}

.perspective .svgicon svg {
	top: 0.25em;
}

button:hover svg,
button:hover svg img {
	fill: #0a4436;
}

div[role="toolbar"] button:hover svg,
div[role="toolbar"] button:hover svg img {
	fill: #fff;
}

.sweet-overlay {
	background-color: rgba(0, 0, 0, 0.7) !important;
}

.showSweetAlert.visible,
.sweet-alert button {
	border-radius: 0 !important;
}

.sweet-alert h2 {
	font-weight: 900 !important;
	text-transform: uppercase !important;
	color: black !important;
}

.sweet-alert button {
	font-weight: 900 !important;
	font-size: 16px !important;
	border: 2px solid #0a4436 !important;
	box-shadow: none !important;

	&:hover {
		color: #0a4436 !important;
		background-color: #fff !important;
	}
}

.sweet-alert button.cancel {
	color: #0a4436 !important;
	border-color: #0a4436 !important;
	background-color: #fff !important;

	&:hover {
		color: #fff !important;
		background-color: #0a4436 !important;
	}
}

button[data-tour-elem="right-arrow"] {
	position: absolute;
	right: 22px;
	bottom: 24px;
}

.reactour__helper:last-child > button {
	position: absolute;
	top: 0;
	right: 22px;
	width: 12px;
	height: 12px;
	color: #5e5e5e;
}

.joystick-base {
	margin: 0 auto;
}
