@import "~animate.css/animate.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~video-react/styles/scss/video-react";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

$green: #0a4436;
$font_family_1: "din-2014",
sans-serif;
$font_family_2: "museo-slab",
serif;

*:focus {
    outline: none;
}

* {
    margin: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    font: 14px/1.4 sans-serif;
}

input,
textarea {
    font: 14px/1.4 sans-serif;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: $font_family_1;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    color: #222;
    font-size: 16px;
    font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font_family_2;
    font-weight: 900;
    text-transform: uppercase;
}

h3 {
    font-size: 18px;
}

b {
    color: black;
    font-weight: 600;
}

.logo {
    width: 64px;
    fill: $green;
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-leave {
    opacity: 1;
}

.fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

#forgot-password {
    display: grid;
    justify-items: center;
    align-items: center;
    height: calc(100vh - 204px);

    &>div {
        padding: 40px;
        border: 1px solid #ccc;
    }
}

.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
}

/* Style the buttons that are used to open the tab content */
.tab button {
    font-family: "museo-slab", serif;
    font-weight: 900;
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 18px;
    width: 50%;
    margin: 0;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
    background-color: #0a4439;
    color: #fff;
}

/* Style the tab content */
.tabcontent {
    padding: 3em 4em;
    border: 1px solid #ccc;
    border-top: none;
    width: 415px;
}

.sidebar {
    padding: 1em;
    grid-row: 2;
    overflow: auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}


#root {
    .rc-steps {
        transition: background-color 0.6s ease;
        padding-top: 4px;
    }

    .rc-steps-item-title {
        margin-bottom: 0;
    }

    .rc-steps-item-title .rc-steps-item-process .rc-steps-item-title {
        color: #0a4436;

    }

    .rc-steps-item-process .rc-steps-item-icon {
        background: #0a4436;
        border-color: #0a4436;
    }

    .rc-steps-item-tail:after {
        background: #0a4436;
    }

    .rc-steps-item-finish .rc-steps-item-icon {
        border-color: #0a4436;
    }

    .rc-steps-item-finish:hover {
        cursor: pointer;
    }

    .rc-steps-item-icon>.rc-steps-icon {
        top: 0;
    }

    div[class^="styles_handle"] {
        width: 10px;
        background: #eee;
        border-left: 1px solid #0a4436;
        border-right: 1px solid #0a4436;
    }

    .slick-prev:before,
    .slick-next:before {
        color: #666;
        top: 40%;
    }

    .slick-prev {
        left: 0;
        z-index: 1000;
    }

    .slick-next {
        right: 0;
        z-index: 1000;
    }
}

kbd {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.1em 0.5em;
    margin: 0 0.2em;
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
    background-color: #f7f7f7;
}

.video-react .video-react-big-play-button {
    display: none !important;
}

.type-dark {
    text-transform: uppercase;
    font-size: 12px;
    max-width: 160px;
    padding-right: 8px;
    padding-left: 8px;
}


/*
 * Animations
 */
@-webkit-keyframes showModal {
    0% {
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
    }

    45% {
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
    }

    80% {
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
    }

    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

@keyframes showModal {
    0% {
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
    }

    45% {
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
    }

    80% {
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
    }

    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

@-webkit-keyframes hideModal {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }

    100% {
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
    }
}

@keyframes hideModal {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }

    100% {
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
    }
}

@keyframes delayIn {
    99% {
        visibility: hidden;
    }

    100% {
        visibility: visible;
    }
}