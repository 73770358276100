#project {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

    .project-item {
        display: inline-block;
        margin: 2em;
    }

    .container {
        position: relative;
        width: 100%;
    }

    .image {
        max-width: 100%;
        height: auto;
    }

    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .3s ease;
        background-color: red;
    }

    .container:hover .overlay {
        opacity: .9;
    }

    .icon {
        color: white;
        font-size: 64px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .icon:hover {
        color: #eee;
    }


}