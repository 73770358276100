$green: #0a4436;

#header {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1em;
    min-height: 4em;
    border-bottom: 1px solid #ccc;
    font-weight: 700;
    text-transform: uppercase;

    a {
        text-decoration: none;
    }

    .logo {
        max-width: 64px;
    }

    .brand {
        grid-column: span 3 / auto;
        display: grid;
        grid-template-columns: 64px repeat(3, 1fr);

        .title {
            color: $green;
            grid-column: span 3 / auto;
            padding-top: 1.2em;

            &:before {
                content: "|";
                padding: 0 0.25em;
            }
        }
    }

    .nav {
        a {
            text-decoration: none;
            color: $green;
        }
    }

    .steps {
        grid-column: span 6 / auto;
        align-self: center;
        display: grid;
        /*grid-template-columns: repeat(4, 1fr);*/

        .nav {
            padding: 0.5em;

            & a.disabled {
                color: #ccc;
            }
        }

        .prev {
            text-align: right;
        }

        .next {
            text-align: left;
        }

        .current-step {
            grid-column: span 6;
            text-align: center;
            font-size: 1.5em;
        }
    }

    .sign-in {
        text-align: right;
        grid-column: span 3 / auto;
        text-transform: uppercase;
        margin-top: 1em;
        color: $green;

        .cta {
            float: right;
            margin-top: 0.5em;
        }

        .icon-wrapper {
            float: right;
        }

        span:before {
            content: "|";
            padding: 0 0.25em;
            font-weight: bold;
        }

        svg.icon {
            width: 32px;
            fill: $green;
            align-items: end;
        }
    }
}