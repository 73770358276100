#slider {

    .wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        overflow: hidden;
    }

    .before,
    .after {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-color: #eee;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        overflow: hidden;
    }
    
    .after {
		.artboard {
			inset-block: 0!important;
			inset-inline: 0!important;
		}
	}
	
	.content-image {
        height: 100%;
    }

    .after {
        width: 125px;
    }

    .scroller {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 100px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        background-color: transparent;
        pointer-events: auto;
        border: 5px solid white;
    }

    .scroller:hover {
        cursor: col-resize;
    }

    .scrolling {
        cursor: col-resize;
    }

    .scroller__thumb {
        width: 100%;
        height: 100%;
        padding: 5px;
    }

    .scroller:before,
    .scroller:after {
        content: " ";
        display: block;
        width: 7px;
        height: calc(100vh - 109px);
        position: absolute;
        left: 50%;
        margin-left: -3.5px;
        z-index: 30;
        transition: 0.1s;
    }

    .scroller:before {
        top: 100%;
    }

    .scroller:after {
        bottom: 100%;
    }

    .scroller:before,
    .scroller:after {
        background: white;
    }

    .beforeLabel, .afterLabel {
        pointer-events: none;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 10px 20px;
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        transition: opacity 0.1s ease-out;
    }

    .beforeLabel {
        left: 5%;
    }

    .afterLabel {
        right: 5%
    }
}