ul {
    padding-left: 0;
}

li {
    list-style: none;
}

.category {
    text-transform: none;

    padding: 5px 0;
    transition: color 0.15s linear;

    font-family: "din-2014", sans-serif;
    color: #1d1d1d;
    font-weight: 700;
    font-size: 22px;

    &.selected {
        color: #117059;
    }

    &:hover {
        cursor: pointer;
        color: #117059;
    }
}

.category:after {
    position: relative;
    top: 2px;
    right: 1px;
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: solid 1px #1d1d1d;
    float: right;
}

.selected:after {
    background-color: #0a4436;
}

.color-box {
    height: 0;
}

.colours-wrapper {
    max-height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
    border: solid 1px #f7f8f9;
}

.colour-container {
    position: relative;
    padding: 3px 0;
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.swatch {
    position: relative;
    height: 32px;
    width: 32px;
    display: block;
    border-radius: 50%;
    background-color: #1d1d1d;
    cursor: pointer;
    margin-left: 5px;
    backface-visibility: hidden !important;

    .icon {
        fill: #fff;
        color: #fff;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(-50%);
        transition: 300ms all ease;
    }

    &.black {
        background-color: #1d1d1d;
    }

    &.cambridge-white {
        background-color: #d7d7d7;
    }

    &.charcoal {
        background-color: #5e5758;
    }

    &.melchers-green {
        background-color: #114034;
    }
}

.products-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;

    .product-wrapper {
        grid-column: span 1/auto;
        transition: box-shadow 0.3s cubic-bezier(.25, .8, .25, 1);

        &.selected-product {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        }

        &:hover {
            cursor: pointer;
        }
    }

    button {
        margin-bottom: 1.5em;
    }

    img {
        transform: scale(0.9);
        transition: ease-in-out transform 0.3s;

        &:hover {
            transform: scale(1);
        }
    }
}



h5 {
    font-family: "din-2014", sans-serif;
    font-size: 14px;
    color: #0a4436;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 14px;
}

.product-wrapper,
.product-title {
    border: solid 1px #f7f8f9;
    color: #0a4436;

    span {
        font-family: "din-2014", sans-serif;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.02em;
        font-size: 15px;
        text-transform: none;
        color: #1d1d1d;

        &.colour-name {
            position: absolute;
            top: 9px;
            left: 36px;
            line-height: 1;
            padding-left: 5px;
            font-family: "din-2014", sans-serif;
            font-weight: 400;
            white-space: nowrap;
            font-size: 14px;
        }
    }

    i {
        color: #1d1d1d;
        float: right;
        margin-top: 2em;
        margin-right: 0.5em;

        &:hover {
            cursor: pointer;
        }
    }
}

.product-title {
    border-bottom: none;
    padding: 0.5em;
    display: block;
    font-family: "din-2014", sans-serif;
    color: #0a4436;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    letter-spacing: 0.02em;
    padding-right: 20px;
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-leave {
    opacity: 1;
}

.fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner,
.spinner-large {

    width: 42px;
    height: 42px;
    stroke: #0a4436;

    &.sm {
        width: 18px;
        height: 18px;
    }
}

.spinner-large {
    width: 64px;
    height: 64px;
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}