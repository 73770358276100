section#home {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: calc(100vh - 105px);

    h1 {
        font-size: 48px;
        margin: 0.67em 10vw;
        color: white;
    }
}

.ad-brick {
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 20vh;

    &.new-project {
        background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../img/bg1.jpg");
    }

    &.open-project {
        background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../img/bg2.jpg");
    }

    a,
    .clickable {
        text-decoration: none;
        color: white;
        margin-top: 40%;
    }

    svg {
        fill: white;
        border-radius: 50%;
        border: 2px solid white;
        width: 72px;
        height: 72px;
        padding: 1em;
    }
}