.aligner {
    display: flex;
    align-items: center;
    min-height: 24em;
    justify-content: center;
}

.aligner-item {
    flex: 1;
    text-align: center;
    max-width: 420px;
}

.aligner-item--top {
    align-self: flex-start;
}

.aligner-item--bottom {
    align-self: flex-end;
}

.aligner-item--fixed {
    flex: none;
    max-width: 50%;
}