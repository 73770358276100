section#draw,
section#crop {
	display: grid;
	grid-template-columns: repeat(3, 1fr) 400px;
	height: calc(100vh - 105px);
	grid-template-rows: auto 1fr;
}

svg#project {
	max-width: 1024px;
	margin: 0 auto;
	display: inherit;
}

.canvas,
.artboard-wrapper {
	text-align: center;
	grid-column: span 3 / auto;
	background: #eee;
	grid-row: 2;
    overflow: hidden;
    position: relative;
}

.artboard {
	background: #eee;
	max-width: 1024px;
	margin: 0 auto;
	width: 1024px;
	height: 768px;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: contain;
    backface-visibility: hidden;
    top: 50%;
    left: 50%;
    position: absolute;
    transform-origin: center center;
}

.artboard-grid {
	position: absolute;
	width: 1024px;
	height: 768px;
	left: 0;
}

.texture-wrapper {
	width: 200vw;
	height: 200vw;
	position: absolute;
}

.product-texture {
	width: 100%;
	height: 100%;
	//  transform-origin: 0 0;
	position: absolute;
	background-color: #dfdbe5;
    transition: background-color 1s linear;
 	backface-visibility: hidden;
	transform-style: preserve-3d;
}

#shape-wrapper {
	width: 1024px;
	height: 768px;
	background-position: center;
	background-size: contain;
    background-repeat: no-repeat !important;
}

.shape-path,
.cut-path {
	width: 1024px;
	height: 768px;
	margin: 0 auto;
	overflow: hidden;
	position: absolute;
    backface-visibility: hidden;
}

path.cut-path {
	&.active {
		stroke-dasharray: 2 3;
		fill-opacity: 0.1;
		stroke: #ff4136;
		fill: #ff4136;
		fill-opacity: 0.5;
	}

	&.cutmode:not(.active) {
		fill: white;
	}
}
.inactive-cut-path.cutmode {
    cursor: pointer;
}
.path, .point, .cut-path.cutmode {
    cursor: pointer;
    &.active {
        cursor: move;
    }
}


.transform-preview-wrapper {
	width: 100%;
	height: 230px;
	position: relative;
	background-image: url("../img/preview-bg.jpg");
	background-size: contain;
	transform: scale(0.75);
	margin-top: -20px;
}

.transform-preview {
	width: 200px;
	height: 148px;
	border: 4px solid #0a4436;
	position: relative;
	margin: 2em 6em;
	top: 2em;

	&:before {
		content: "Before";
		margin-top: -20px;
		margin-left: 10px;
		padding: 0 8px;
		border: 4px solid #0a4436;
		width: fit-content;
		display: block;
		color: #0a4436;
		font-weight: 600;
	}

	&.after {
		&:before {
			content: "After";
            border-color: red;
            margin-top: -27px;
			color: red;
		}

		background-image: linear-gradient(45deg, rgba(255, 0, 0, 0.5) 25%, transparent 25%), linear-gradient(135deg, rgba(255, 0, 0, 0.5) 25%, transparent 25%),
			linear-gradient(45deg, transparent 75%, rgba(255, 0, 0, 0.5) 75%), linear-gradient(135deg, transparent 75%, rgba(255, 0, 0, 0.5) 75%);
		background-size: 25px 25px;
		background-position: 0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px;
	}
}

.pt {
	position: absolute;
	background-color: #ff4136;
	width: 48px;
	height: 48px;
	padding: 8px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	border-radius: 50%;

	&:hover {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}

	cursor: pointer;
	z-index: 100;

	&.tl {
		margin-top: -38px;
		margin-left: -38px;
	}

	&.tr {
		margin-top: -38px;
		margin-left: -10px;
	}

	&.bl {
		margin-top: -10px;
		margin-left: -38px;
	}

	&.br {
		margin-top: -10px;
		margin-left: -10px;
	}

	&.tl,
	&.br {
		cursor: nwse-resize;
	}

	&.tr,
	&.bl {
		cursor: nesw-resize;
	}

	& > svg {
		fill: white;
		fill-opacity: 0.9;
	}
}

.lines {
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
}

.lines line {
	stroke: #ff4136;
	stroke-opacity: 0.9;
	stroke-width: 2px;
	stroke-dasharray: 6;
	z-index: 100;
}

.shape-path {
	opacity: 1;

	&:hover {
		opacity: 0.75;
	}
}

.path {
	stroke: whitesmoke;
	stroke-width: 4px;
	stroke-linecap: round;

	&.active {
		stroke: #ffbd44;
		fill: #ffdc00;

		&.cutmode {
			fill: whitesmoke;
			stroke-width: 2px;
			pointer-events: none;
		}
	}

	&.scale {
		fill: #ffbd44;
	}
}

.inactive-cut-path {
	fill: transparent;
	stroke: #ccc;
	stroke-dasharray: 2 3;
}

.point {
	cursor: pointer;
	fill: #fff;
	stroke: #ccc;
	stroke-width: 5px;
	transition: stroke ease 0.3s;

	&:hover {
		stroke: #ffbd44;
	}
}

.scale-point {
	cursor: pointer;
	stroke: #ffbd44;
	stroke-width: 5px;
	transition: stroke ease 0.3s;
}

.point-group.active {
	.point {
		stroke: #ffbd44;
	}

	.anchor {
		opacity: 1;

		&:hover {
			cursor: pointer;
		}
	}
}

line.anchor-line {
	stroke: #cdcdcd;
}

line.anchor-line {
    stroke-dasharray: 6;
}

g.shapes path {
	fill: grey;
}

.grid {
	fill: none;
	stroke: #eee;
	opacity: 0.3;

	.major {
		fill: none;
		stroke: #eee;
		stroke-width: 0.25px;
	}
}

.grid.is-hidden {
	opacity: 0;
}

.zoom-container {
	position: relative;
	right: 25%;
	width: 76px;
	color: #0a4436;
	margin: -10em auto 0 auto;

	i {
		background: #eee;
		padding: 0.6em;

		&:first-child {
			&:after {
				margin-left: 10px;
				margin-right: -8px;
				content: "|";
			}
		}

		&:hover {
			color: #222;
			cursor: pointer;
		}
	}
}

.fab {
	left: 0;
	bottom: 0px;
	box-sizing: border-box;
	margin: 25px;
	position: fixed;
	white-space: nowrap;
	z-index: 30;
	padding-left: 0;

	> button {
		height: 56px;
		width: 56px;
		z-index: 20;
		background-color: #666666;
		display: -webkit-inline-flex;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		position: relative;
		border: none;
		border-radius: 50%;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
		cursor: pointer;
		outline: none;
		padding: 0;
		font-weight: bold;
		color: #f1f1f1;
		text-decoration: none;
		font-size: 18px;
	}
}

div[role="toolbar"] {
	& > button.tour-active {
		color: white;
	}
}

#draw > div.canvas > div > div > div > svg > rect {
	fill: #eee !important;
}

.rotation-example {
	width: 150px;
	height: 150px;
	border: 4px solid #eee;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	display: inline-block;
    margin: 14px;
    
	> svg {
		stroke: white;
		stroke-width: 8px;
		transition: fill ease 0.3s;

		&:hover {
			fill: #0a4436;
		}
	}

	&:hover {
		border-color: #0a4436;
	}

	> .example {
		content: "";
		position: absolute;
		width: 200%;
		height: 200%;
		top: -50%;
		left: -50%;
		z-index: -1;
		background: 0 0 repeat;
	}
}

._loading_overlay_spinner {
    cursor: wait;
    &::before {
        content: "Please Wait...";
        display: block;
        width: 200%;
        margin-left: -50%;
        padding-top: 80px;
        margin-bottom: -10px;
    }
}