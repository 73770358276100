.cropper {
	grid-column: span 3 / auto;
	grid-row: 2;
	overflow: hidden;
	background-size: 20px 20px;
	background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
	text-align: center;
	padding: 1em;
}

.ReactCrop__image {
	max-height: calc(100vh - 105px);
}

img[alt="Crop"] {
	padding: 5px;
	border: 1px solid #f8f9fa;
	margin-top: 2em;
	object-fit: cover;
	width: 100%;
}

.ReactCrop__crop-selection {
	border-width: 2px;
}

.ReactCrop__drag-handle {
	width: 20px;
	height: 20px;
	border-width: 2px;
}

.ReactCrop .ord-nw {
	margin-top: -10px;
	margin-left: -10px;
}

.ReactCrop .ord-n {
	margin-top: -10px;
	margin-left: -10px;
}

.ReactCrop .ord-ne {
	margin-top: -10px;
	margin-right: -10px;
}

.ReactCrop .ord-e {
	margin-top: -10px;
	margin-right: -10px;
}

.ReactCrop .ord-se {
	margin-bottom: -10px;
	margin-right: -10px;
}

.ReactCrop .ord-s {
	margin-bottom: -10px;
	margin-left: -10px;
}

.ReactCrop .ord-sw {
	margin-bottom: -10px;
	margin-left: -10px;
}

.ReactCrop .ord-w {
	margin-top: -10px;
	margin-left: -10px;
}